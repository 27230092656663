import { usePaginatedTableFilters } from 'components/shared/table/TableFilters';
import { useEffect, useMemo } from 'react';
import { PluginHook, TableOptions, useTable } from 'react-table';
import { Pagination } from 'types/leads';

interface Options<D extends object> {
  tableId?: string;
  columns: TableOptions<D>['columns'];
  data: TableOptions<D>['data'];
  initialState?: Omit<TableOptions<D>['initialState'], 'hiddenColumns' | 'pageIndex' | 'pageSize'>;
  hiddenColumns?: TableOptions<D>['hiddenColumns'];
  getRowId?: TableOptions<D>['getRowId'];
}

type Plugins<D extends object> = PluginHook<D>[];

const EMPTY_OBJECT = {} as const;
const EMPTY_ARRAY = [] as const;

export function useNeuronTable<D extends object>(
  {
    tableId,
    columns,
    data,
    initialState = EMPTY_OBJECT,
    hiddenColumns = EMPTY_ARRAY,
    getRowId
  }: Options<D>,
  ...plugins: Plugins<D>
) {
  const {
    selectedRowIds: selectedRowIdsFilter,
    pageSize: pageSizeFilter,
    pageIndex: pageIndexFilter,
    nextPageIndex,
    prevPageIndex,
    setPageSize,
    setPageIndex,
    setSelectedRowIds
  } = usePaginatedTableFilters(tableId);

  const selectedRowIds = useMemo(
    () =>
      selectedRowIdsFilter.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {} as Record<string, boolean>),
    [selectedRowIdsFilter]
  );

  const reactTable = useTable<D>(
    {
      columns,
      data,
      initialState: {
        ...initialState,
        hiddenColumns,
        pageIndex: pageIndexFilter,
        pageSize: pageSizeFilter,
        selectedRowIds
      },
      autoResetPage: false,
      autoResetSelectedRows: true,
      autoResetSortBy: false,
      getRowId,
      useControlledState: (state) => {
        return useMemo(() => {
          return {
            ...state,
            pageIndex: pageIndexFilter,
            pageSize: pageSizeFilter
          };
          // Don't forget to add dependencies here when adding new properties to state!
          // https://github.com/TanStack/table/issues/3025
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state, pageIndexFilter, pageSizeFilter, selectedRowIds]);
      }
    },
    ...plugins
  );

  const {
    setHiddenColumns,
    pageCount,
    state: { pageIndex, selectedRowIds: internalSelectedRowIds }
  } = reactTable;

  // No event handler for selected rows in React Table v7 so need to use uncontrolled state
  useEffect(() => {
    setSelectedRowIds(Object.keys(internalSelectedRowIds));
  }, [internalSelectedRowIds, setSelectedRowIds]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if (data.length > 0 && pageIndex >= pageCount) {
      setPageIndex(Math.max(0, pageCount - 1));
    }
  }, [data.length, pageCount, pageIndex, setPageIndex]);

  const pagination: Pagination = {
    pageSize: reactTable.state.pageSize,
    setPageSize: (value: number) => setPageSize(value),
    pageIndex: reactTable.state.pageIndex,
    pageOptionsLength: reactTable.pageOptions.length,
    nextPage: () => reactTable.canNextPage && nextPageIndex(),
    previousPage: () => reactTable.canPreviousPage && prevPageIndex(),
    gotoPage: (value: number) => reactTable.pageCount > value && value >= 0 && setPageIndex(value),
    pageCount: reactTable.pageCount,
    canNextPage: reactTable.canNextPage,
    canPreviousPage: reactTable.canPreviousPage
  };

  return {
    selectedFlatRows: reactTable.selectedFlatRows,
    headerGroups: reactTable.headerGroups,
    getTableProps: reactTable.getTableProps,
    getTableBodyProps: reactTable.getTableBodyProps,
    prepareRow: reactTable.prepareRow,
    page: reactTable.page,
    pagination: pagination,
    setFilter: reactTable.setFilter,
    setGlobalFilter: reactTable.setGlobalFilter
  };
}
