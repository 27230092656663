import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTableFilter } from '../../../shared/table/TableFilters';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setConsultantLocation, setLeadAge, setLeadLocation } from 'redux/filterReducer';

export enum LeadResult {
  Won = 'won',
  Lost = 'lost',
  None = 'none'
}

const getResult = (value: string): LeadResult | null => {
  switch (value) {
    case LeadResult.Won:
      return LeadResult.Won;
    case LeadResult.Lost:
      return LeadResult.Lost;
    case LeadResult.None:
      return LeadResult.None;
    default:
      return null;
  }
};

export const useLeadStatusFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateFilter = (key: string, value: string | boolean | null | undefined) => {
    setSearchParams(
      (prev) => {
        if (value === undefined || value === null) {
          prev.delete(key);
        } else {
          prev.set(key, value.toString());
        }
        return prev;
      },
      { replace: true }
    );
  };

  const setResult = (value: LeadResult | null) => updateFilter('result', value?.toString());
  const setAnswered = (value: boolean | null) => updateFilter('answered', value);
  const setRelevant = (value: boolean | null) => {
    updateFilter('relevant', value);
  };
  const setFavorite = (value: boolean | null) => updateFilter('favorite', value);
  const setAssigned = (value: boolean | null) => updateFilter('assigned', value);

  const value = useMemo(
    () => ({
      result: searchParams.has('result') ? getResult(searchParams.get('result')!) : null,
      answered: searchParams.has('answered') ? searchParams.get('answered') == 'true' : null,
      relevant:
        searchParams.has('relevant') && searchParams.get('relevant') == ''
          ? null
          : searchParams.has('relevant')
          ? searchParams.get('relevant') == 'true'
          : true,
      favorite: searchParams.has('favorite') ? searchParams.get('favorite') == 'true' : null,
      assigned: searchParams.has('assigned') ? searchParams.get('assigned') == 'true' : null
    }),
    [searchParams]
  );

  const count = Object.values(value).filter((v) => v !== null).length;

  return {
    value,
    count,
    setAnswered,
    setResult,
    setRelevant,
    setFavorite,
    setAssigned
  };
};

export const useLeadSearchFilter = () => {
  return useTableFilter<string>(undefined, 'search', '');
};

export const useLeadAgeFilter = () => {
  const dispatch = useDispatch();
  const value = useSelector((state: RootState) => state.filter.leadAge);

  const setValue = (value: number) => {
    dispatch(setLeadAge(value));
  };

  return { value, setValue };
};

export const useLeadLocationFilter = () => {
  const dispatch = useDispatch();
  const value = useSelector((state: RootState) => state.filter.leadLocation);

  const setValue = (value: string | null) => {
    if (value === null) {
      dispatch(setLeadLocation(null));
      dispatch(setConsultantLocation(null));
    } else {
      dispatch(setLeadLocation(value));
      if (value == 'Kalmar' || value == 'Jönköping' || value == 'Växjö') {
        dispatch(setConsultantLocation('Småland'));
      } else if (value == 'Karlshamn') {
        dispatch(setConsultantLocation('Karlskrona/Karlshamn'));
      } else if (value === 'Remote') {
        dispatch(setConsultantLocation(null));
      } else if (value == '') {
        dispatch(setConsultantLocation(null));
      } else {
        dispatch(setConsultantLocation(value));
      }
    }
  };

  return { value, setValue };
};
