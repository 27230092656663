import { useNavigate, useSearchParams } from 'react-router-dom';
import { UsersFilters } from './filter_users/UsersFilters';
import { useGetLeadQuery } from 'redux/leadApi';
import styles from './_actionBar.module.css';
import { ShownFilter } from './ShownFilter';
import { LocationFilter } from './LocationFilter';
import { SkillsFilter } from './SkillsFilter';
import { Button } from '@mui/material';
import AvailabilityFilter from './AvailabilityFilter';
import LeadInfo from './LeadInfo';
import LeadAgeFilter from './LeadAgeFilter';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLeadAgeFilter } from 'components/features/leads/action-bar/LeadFilters';
import {
  useConsultantAvailabilityFilter,
  useConsultantLocationFilter,
  useConsultantSearchFilter,
  useConsultantSkillsFilter,
  useShowSelectedConsultantsFilter
} from 'components/features/users/action-bar/ConsultantFilters';

type Props = {
  allLocations: string[];
  allSkills: string[];
  isLeadsView: boolean;
  setIsLeadsView: (value: boolean) => void;
  isForLead: boolean;
  showApplyHereLink?: boolean;
};

export function ActionBar(props: Props) {
  const { allLocations, allSkills, isLeadsView, setIsLeadsView, showApplyHereLink } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get('forLead');
  const { data: lead, isFetching } = useGetLeadQuery(leadId ?? skipToken);

  const { value: leadAge, setValue: setLeadAge } = useLeadAgeFilter();
  const { value: location, setValue: setLocation } = useConsultantLocationFilter();
  const { value: availability, setValue: setAvailability } = useConsultantAvailabilityFilter();
  const { value: search, setValue: setSearch } = useConsultantSearchFilter();
  const { value: skills, setValue: setSkills } = useConsultantSkillsFilter();
  const { value: showSelectedConsultants, setValue: setShowSelectedConsultants } =
    useShowSelectedConsultantsFilter();

  const handleGoBackToLead = (): void => {
    navigate(`/leads/${leadId}`, { state: { returnLocation: 'leads' } });
  };

  return (
    <div className={styles['action-bar']}>
      {leadId && (
        <div className={styles['action-bar-button-text']}>
          <span className={styles['action-bar-text']}>
            <LeadInfo
              lead={lead}
              isFetching={isFetching}
              handleGoBackToLead={handleGoBackToLead}
              showApplyHereLink={showApplyHereLink}
            />
          </span>
        </div>
      )}

      <div className={styles['action-bar-filters-wrapper']}>
        <ShownFilter value={showSelectedConsultants} onChange={setShowSelectedConsultants} />
        <LocationFilter value={location} allLocations={allLocations} onChange={setLocation} />

        <LeadAgeFilter value={leadAge} onChange={setLeadAge} />

        <AvailabilityFilter value={availability} onChange={setAvailability} />

        <SkillsFilter allSkills={allSkills} value={skills} onChange={setSkills} />
        <UsersFilters defaultValue={search} onChange={setSearch} />

        <Button
          className={styles['action-bar-matchingLeads-button']}
          onClick={() => setIsLeadsView(!isLeadsView)}
          sx={{ height: '40px' }}>
          {isLeadsView ? 'Show Consultant Details' : 'Show Matching Leads'}
        </Button>
      </div>
    </div>
  );
}
