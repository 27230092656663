import { useSearchParams } from 'react-router-dom';

import UsersContent from 'components/features/users/display-users/UsersContent';
import PageLayout from 'components/layouts/PageLayout';

import { useGetAllUsersQuery } from 'redux/userApi';
import { useGetFindCompetenceForLeadsQuery } from 'redux/leadApi';
import {
  getUsersWithCompetenceForLead,
  sortUsersByMatch
} from 'components/features/users/display-users/_functionsDisplayUsers';

import * as TypesLeads from 'types/leads';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  AvailabilityFilter,
  useConsultantAvailabilityFilter,
  useConsultantLocationFilter
} from 'components/features/users/action-bar/ConsultantFilters';
import React, { useCallback, useMemo } from 'react';
import { useSyncGlobalFilters } from 'components/features/leads/action-bar/GlobalFilters';

const Users = () => {
  useSyncGlobalFilters();
  const [searchParams] = useSearchParams();
  const forLead = searchParams.get('forLead');
  const hiddenColumns = useMemo(
    () => (forLead ? ['assigned', 'location'] : ['relevance']),
    [forLead]
  );

  const { data: fetchedUsers = [], isError, isLoading: isLoadingUsers } = useGetAllUsersQuery();
  // Important we use currentData here, as we don't want data from a previous lead to be used
  const { currentData: usersWithCompetenceForLead, isLoading: isLoadingCompetencesForLead } =
    useGetFindCompetenceForLeadsQuery(forLead ?? skipToken);
  const enrichedUsers = useMemo(
    () => getUsersWithCompetenceForLead(fetchedUsers, usersWithCompetenceForLead?.matches),
    [fetchedUsers, usersWithCompetenceForLead]
  );

  const { value: availabilityFilter } = useConsultantAvailabilityFilter();
  const { value: locationFilter } = useConsultantLocationFilter();

  const isLoading = isLoadingUsers || isLoadingCompetencesForLead;

  const maybeFilterByAvailability = useCallback(
    (users: TypesLeads.User[]) => {
      if (forLead && availabilityFilter && availabilityFilter != AvailabilityFilter.All) {
        let minAvailability;
        switch (availabilityFilter) {
          case AvailabilityFilter.FullyAvailable:
            minAvailability = 1;
            break;
          case AvailabilityFilter.PartlyAvailable:
            minAvailability = 0.9;
            break;
        }
        return users.filter(
          (user) => (user.competenceStatus?.matchWeights?.availability ?? 0) >= minAvailability
        );
      }
      return users;
    },
    [availabilityFilter, forLead]
  );

  const maybeFilterByAssigned = useCallback(
    (users: TypesLeads.User[]) => {
      if (availabilityFilter && availabilityFilter != AvailabilityFilter.All) {
        return users.filter((user) => {
          switch (availabilityFilter) {
            case AvailabilityFilter.FullyAvailable:
              return user.assigned <= 0;
            case AvailabilityFilter.PartlyAvailable:
              return user.assigned > 0 && user.assigned <= 99;
            default:
              return true;
          }
        });
      }
      return users;
    },
    [availabilityFilter]
  );

  const maybeFilterByLocation = useCallback(
    (users: TypesLeads.User[]) => {
      if (locationFilter && locationFilter != 'All') {
        return users.filter((user) => user.location == locationFilter);
      }
      return users;
    },
    [locationFilter]
  );

  const applyFilters = useCallback(
    (users: TypesLeads.User[]) => {
      let filteredUsers = forLead ? maybeFilterByAvailability(users) : maybeFilterByAssigned(users);
      filteredUsers = maybeFilterByLocation(filteredUsers);
      return filteredUsers;
    },
    [forLead, maybeFilterByAssigned, maybeFilterByAvailability, maybeFilterByLocation]
  );

  const filterAndSortUsers = useCallback(
    (users: TypesLeads.User[]) => {
      const filteredUsers = applyFilters(users);
      return sortUsersByMatch(filteredUsers);
    },
    [applyFilters]
  );

  const users = useMemo(
    () => filterAndSortUsers(enrichedUsers),
    [enrichedUsers, filterAndSortUsers]
  );

  if (!users) {
    return (
      <PageLayout>
        <span>No data found</span>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <UsersContent
        allUsers={enrichedUsers}
        users={users}
        isLoading={isLoading}
        isError={isError}
        matchingLoaded={!isLoadingCompetencesForLead}
        hiddenColumns={hiddenColumns}
      />
    </PageLayout>
  );
};

export default Users;
