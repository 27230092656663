import { capitalize } from 'utils/string';
import { startTransition, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const createKey = (tableId: string | undefined, paramId: string) =>
  tableId ? `${tableId}${capitalize(paramId)}` : paramId;

export const usePaginatedTableFilters = (tableId?: string | undefined) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedRowIdsKey = createKey(tableId, 'selectedRowIds');
  const pageSizeKey = createKey(tableId, 'pageSize');
  const pageIndexKey = createKey(tableId, 'pageIndex');

  const selectedRowIds = useMemo(() => {
    const ids = searchParams.getAll(selectedRowIdsKey);
    return ids.length ? ids : [];
  }, [selectedRowIdsKey, searchParams]);

  const setSelectedRowIds = useCallback(
    (ids: (string | number)[]) => {
      startTransition(() => {
        setSearchParams(
          (prev) => {
            prev.delete(selectedRowIdsKey);
            ids.forEach((id) => {
              prev.append(selectedRowIdsKey, id.toString());
            });
            return prev;
          },
          { replace: true }
        );
      });
    },
    [selectedRowIdsKey, setSearchParams]
  );

  const pageSize = useMemo(
    () => Number(searchParams.get(pageSizeKey)) || 10,
    [pageSizeKey, searchParams]
  );

  const setPageSize = useCallback(
    (size: number) => {
      startTransition(() => {
        if (size === Number(searchParams.get(pageSizeKey))) {
          return;
        }
        setSearchParams(
          (prev) => {
            prev.set(pageSizeKey, size.toString());
            return prev;
          },
          { replace: true }
        );
      });
    },
    [pageSizeKey, searchParams, setSearchParams]
  );

  const pageIndex = useMemo(
    () => Number(searchParams.get(pageIndexKey)) || 0,
    [pageIndexKey, searchParams]
  );

  const nextPageIndex = useCallback(() => {
    startTransition(() => {
      setSearchParams(
        (prev) => {
          const prevPageIndex = Number(prev.get(pageIndexKey)) || 0;
          prev.set(pageIndexKey, (prevPageIndex + 1).toString());
          return prev;
        },
        { replace: true }
      );
    });
  }, [pageIndexKey, setSearchParams]);

  const prevPageIndex = useCallback(() => {
    startTransition(() => {
      setSearchParams(
        (prev) => {
          const prevPageIndex = Number(prev.get(pageIndexKey)) || 0;
          prev.set(pageIndexKey, Math.max(0, prevPageIndex - 1).toString());
          return prev;
        },
        { replace: true }
      );
    });
  }, [pageIndexKey, setSearchParams]);

  const setPageIndex = useCallback(
    (page: number) => {
      startTransition(() => {
        if (page === Number(searchParams.get(pageIndexKey))) {
          return;
        }
        setSearchParams(
          (prev) => {
            prev.set(pageIndexKey, page.toString());
            return prev;
          },
          { replace: true }
        );
      });
    },
    [pageIndexKey, searchParams, setSearchParams]
  );

  return {
    selectedRowIds,
    setSelectedRowIds,
    pageSize,
    setPageSize,
    pageIndex,
    setPageIndex,
    nextPageIndex,
    prevPageIndex
  };
};

// These function overloads make it possible for TypeScript to infer that the returned `value`
// is not nullable when a default value is always present.
export function useTableFilter<T extends string>(
  tableId: string | undefined,
  filterName: string
): { value: T | null; setValue: (value: T | null) => void };

export function useTableFilter<T extends string>(
  tableId: string | undefined,
  filterName: string,
  defaultValue: T
): { value: T; setValue: (value: T | null) => void };

export function useTableFilter<T extends string>(
  tableId: string | undefined,
  filterName: string,
  defaultValue?: T
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const key = createKey(tableId, filterName);

  const setValue = useCallback(
    (value: T | null) => {
      startTransition(() => {
        setSearchParams(
          (prev) => {
            if (value === null) {
              prev.delete(key);
            } else {
              prev.set(key, value);
            }
            return prev;
          },
          { replace: true }
        );
      });
    },
    [key, setSearchParams]
  );

  const value =
    defaultValue === undefined
      ? (searchParams.get(key) as T | null)
      : ((searchParams.get(key) ?? defaultValue) as T);

  return {
    value,
    setValue
  };
}

export function useTableNumberFilter(
  tableId: string | undefined,
  filterName: string
): { value: number | null; setValue: (value: number | null) => void };

export function useTableNumberFilter(
  tableId: string | undefined,
  filterName: string,
  defaultValue: number
): { value: number; setValue: (value: number | null) => void };

export function useTableNumberFilter(
  tableId: string | undefined,
  filterName: string,
  defaultValue?: number | null
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const key = createKey(tableId, filterName);

  const setValue = useCallback(
    (value: number | null) => {
      startTransition(() => {
        setSearchParams(
          (prev) => {
            if (value === null) {
              prev.delete(key);
            } else {
              prev.set(key, value.toString());
            }
            return prev;
          },
          { replace: true }
        );
      });
    },
    [key, setSearchParams]
  );

  return {
    value: searchParams.has(key) ? parseInt(searchParams.get(key)!) : defaultValue,
    setValue
  };
}

export const useTableBooleanFilter = (tableId: string | undefined, filterName: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const key = createKey(tableId, filterName);

  const setValue = useCallback(
    (value: boolean | null) => {
      startTransition(() => {
        setSearchParams(
          (prev) => {
            if (value === null) {
              prev.delete(key);
            } else {
              prev.set(key, String(value));
            }
            return prev;
          },
          { replace: true }
        );
      });
    },
    [key, setSearchParams]
  );

  return {
    value: searchParams.get(key) === 'true',
    setValue
  };
};

export const useTableListFilter = <T extends string[]>(
  tableId: string | undefined,
  filterName: string,
  defaultValue?: T | null
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const key = useMemo(() => createKey(tableId, filterName), [tableId, filterName]);

  // The empty array returned by getAll is not a stable reference, so we need to useMemo to
  // avoid potential infinite rerenders in consuming components.
  const value = useMemo(() => searchParams.getAll(key), [key, searchParams]);

  const setValue = useCallback(
    (value: string[] | null) => {
      startTransition(() => {
        setSearchParams(
          (prev) => {
            prev.delete(key);
            if (value != null) {
              value.forEach((skill) => prev.append(key, skill));
            }
            return prev;
          },
          { replace: true }
        );
      });
    },
    [key, setSearchParams]
  );

  return { value: value || defaultValue, setValue };
};
