import React from 'react';
import styles from './_usersFilters.module.css';
import SearchBar from 'components/shared/search-bar/SearchBar';

interface Props {
  defaultValue: string;
  onChange: (value: string) => void;
}

export const UsersFilters = (props: Props) => {
  const { defaultValue, onChange } = props;
  return (
    <div className={styles['filters']}>
      <SearchBar defaultValue={defaultValue} onChange={onChange} label="Search consultants" />
    </div>
  );
};
