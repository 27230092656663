import { useState, MouseEvent, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import LinedStarIcon from '@mui/icons-material/StarOutlineRounded';
import FilledStarIcon from '@mui/icons-material/GradeRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ButtonIcon from 'components/shared/buttons/button-icon/ButtonIcon';
import { RootState } from 'redux/store';
import { isFavorite } from './_functionsLead';
import { useRemoveLeadMutation } from 'redux/leadApi';
import {
  useAddFavoriteMutation,
  useGetFavoritesQuery,
  useRemoveFavoriteMutation
} from 'redux/favoriteApi';
import * as TypesLeads from 'types/leads';
import styles from './_lead.module.css';
import { ToastItem, toast } from 'react-toastify';
import LeadToast from 'components/shared/toasts/lead-toast/LeadToast';
import { TOASTS_DELETE_AUTOCLOSE } from 'constants/constants';
import ShareLead from './ShareLead';

interface Props {
  lead?: TypesLeads.Lead;
  setIsLeadFavorite: Dispatch<SetStateAction<boolean>>;
  isLeadFavorite: boolean;
}

function LeadHeader(props: Props) {
  const { lead, setIsLeadFavorite, isLeadFavorite } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { user: loggedInUser } = useSelector((state: RootState) => state.auth);
  const [removeLead] = useRemoveLeadMutation();
  const { data: favorites } = useGetFavoritesQuery();
  const [addToFavorite] = useAddFavoriteMutation();
  const [removeFavorite] = useRemoveFavoriteMutation();
  const navigate = useNavigate();
  const location = useLocation();

  setIsLeadFavorite(isFavorite(favorites, loggedInUser?.userId, lead?.leadId));

  const handleRemoveLead = (): void => {
    if (lead?.leadId) {
      location.state?.fromConsultant ? navigate('/consultants') : navigate('/');
      toast.info(<LeadToast text="Lead will be deleted" />, {
        data: { cancelDelete: false },
        autoClose: TOASTS_DELETE_AUTOCLOSE
      });
      toast.onChange((payload: ToastItem<Record<string, boolean>>) => {
        if (payload.status === 'removed') {
          if (!payload.data.cancelDelete) {
            removeLead(lead?.leadId);
          }
        }
      });
    }
  };

  const handleCloseDialog = (): void => {
    setIsOpen(false);
  };

  const handleOpenDialog = (): void => {
    setIsOpen(true);
  };

  const handleFavorite = (): void => {
    if (!lead) {
      return;
    }

    if (!isLeadFavorite) {
      try {
        addToFavorite({ leadId: lead?.leadId });
      } catch (error: unknown) {
        console.error(error);
      }
    } else {
      try {
        removeFavorite({ leadId: lead.leadId });
      } catch (error: unknown) {
        console.error(error);
      }
    }
  };

  const handleFindCompetence = (event: MouseEvent): void => {
    event.preventDefault();

    if (lead) {
      navigate({
        pathname: '/consultants',
        search: `?forLead=${lead.leadId}`
      });
    }
  };

  const handleGoBackToLeads = (): void => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <div className={styles['lead-content-header']}>
      <div className={styles['lead-content-header-left']}>
        <button className={styles['lead-content-header-go-back']} onClick={handleGoBackToLeads}>
          <ChevronLeftRoundedIcon />
          <span style={{ marginRight: '5px' }}>
            {location.state?.fromConsultant
              ? 'Consultants'
              : location.state?.returnLocation === 'singleConsultant'
              ? location.state?.firstName
              : 'Leads'}
          </span>
        </button>
      </div>

      <div>
        <Button
          size="small"
          className={styles['lead-content-header-delete-lead-button']}
          onClick={() => handleOpenDialog()}>
          <ButtonIcon>
            <DeleteOutlineRoundedIcon />
          </ButtonIcon>
          <span>Delete lead</span>
        </Button>

        <Dialog open={isOpen} onClose={() => handleCloseDialog()}>
          <DialogTitle>{lead?.companyName}</DialogTitle>

          <DialogContent>
            <DialogContentText>Are you sure you want to delete this lead?</DialogContentText>
          </DialogContent>

          <DialogActions className={styles['lead-header-dialog-footer']}>
            <Button
              onClick={() => handleCloseDialog()}
              sx={{ '&&': { marginRight: '5px' } }}
              className={styles['lead-content-header-favorite-lead-button']}>
              Cancel
            </Button>
            <Button
              onClick={handleRemoveLead}
              sx={{ '&&': { marginRight: '5px' } }}
              className={styles['lead-content-header-delete-lead-button']}>
              Delete Lead
            </Button>
          </DialogActions>
        </Dialog>

        <ShareLead lead={lead} />

        {isLeadFavorite ? (
          <Button
            size="small"
            onClick={handleFavorite}
            className={styles['lead-content-header-favorite-lead-button']}>
            <ButtonIcon>
              <FilledStarIcon />
            </ButtonIcon>
            <span>Remove favorite</span>
          </Button>
        ) : (
          <Button
            size="small"
            onClick={handleFavorite}
            className={styles['lead-content-header-favorite-lead-button']}>
            <ButtonIcon>
              <LinedStarIcon />
            </ButtonIcon>
            <span>Add to favorites</span>
          </Button>
        )}

        {
          <Button
            size="small"
            style={{
              backgroundColor: '#37506A',
              paddingLeft: '24px',
              paddingRight: '24px',
              height: '32px',
              color: '#FFFFFF'
            }}
            onClick={handleFindCompetence}>
            <ButtonIcon>
              <PersonSearchRoundedIcon />
            </ButtonIcon>
            <span>Find competence</span>
          </Button>
        }
      </div>
    </div>
  );
}

export default LeadHeader;
