import React from 'react';
import { Box } from '@mui/material';
import styles from '../_displayUsers.module.css';
import * as TypesLeads from 'types/leads';
import { Row } from 'react-table';
import ConsultantLeads from './ConsultantLeads';
import UserLeads from '../single-user/UserLeads';
import {
  useLeadAgeFilter,
  useLeadLocationFilter
} from 'components/features/leads/action-bar/LeadFilters';

type Props = {
  users: Row<TypesLeads.User>[];
  singleUser: TypesLeads.User;
};

const ConsultantLeadsOverview = (props: Props) => {
  const { users, singleUser } = props;
  const { value: leadAge } = useLeadAgeFilter();
  const { value: leadLocation } = useLeadLocationFilter();

  return (
    <Box component="div" className={styles['users-table-container-information']}>
      <div className={styles['users-table-matching-leads-header']}>
        <p>Matching leads for selected consultants</p>
      </div>

      {users.length > 1 ? (
        users.map((user) => (
          <ConsultantLeads
            key={user.original.userId}
            user={user.original}
            leadAge={leadAge}
            leadLocation={leadLocation}
          />
        ))
      ) : (
        <UserLeads key={singleUser?.userId} user={singleUser} />
      )}
    </Box>
  );
};

export default ConsultantLeadsOverview;
