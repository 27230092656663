import React, { useState } from 'react';
import { Avatar } from '@mui/material';
import * as TypesLeads from 'types/leads';
import styles from './_displayConsultantLeads.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useGetAllLeadsQuery, useGetFindTopFiveCompetenceForUserQuery } from 'redux/leadApi';
import ConsultantLeadsRow from './ConsultantLeadsRow';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
import ErrorMessage from 'components/shared/error/ErrorMessage';

interface Props {
  user: TypesLeads.User;
  leadAge: number;
  leadLocation: string | null;
}

const ConsultantLeads = (props: Props) => {
  const { user, leadAge, leadLocation } = props;
  const [isConsultantOpen, setIsConsultantOpen] = useState(true);
  const locationName = leadLocation ? leadLocation : '';

  const {
    data: userWithCompetenceForLeads,
    isFetching: isFetchingUserWithCompetenceForLeads,
    isError
  } = useGetFindTopFiveCompetenceForUserQuery({
    userId: user.userId ?? 0,
    leadsAge: leadAge,
    locationName: locationName
  });

  const { data: leadsOriginal = [], isFetching: isFetchingLeads } = useGetAllLeadsQuery({
    leadsAge: leadAge,
    leadsLocationFilter: locationName
  });

  const isFetching = isFetchingUserWithCompetenceForLeads || isFetchingLeads;

  const finalLeads = userWithCompetenceForLeads?.matches.map((lead) => {
    const foundLead = leadsOriginal[leadsOriginal.findIndex((item) => item.leadId === lead.leadId)];
    return {
      ...foundLead,
      competenceStatus: {
        absences: lead.absences,
        userId: lead.userId,
        leadId: lead.leadId,
        weight: lead.weight,
        matchWeights: {
          tag: lead.matchWeights.tag,
          location: lead.matchWeights.location,
          availability: lead.matchWeights.availability
        }
      }
    };
  });

  return (
    <>
      <div
        className={styles['users-selected-matching-leads-header']}
        onClick={() => setIsConsultantOpen((prev) => !prev)}>
        <div className={styles['column-avatar']}>
          <Avatar src={user.imageUrl} />
        </div>
        <p className={styles['column-companyName']}>{`${user.firstName} ${user.lastName}`}</p>
        <p className={styles['column-role']}>Role</p>
        <p className={styles['column-skills']}>Skills</p>
        <p className={styles['column-availability']}>Available</p>
        <p className={styles['column-location']}>Location</p>
        <p className={styles['column-dueDate']}>Due date</p>
        <p className={styles['column-status']}>Status</p>
        <p className={styles['column-findCompetence']} />
        {isConsultantOpen ? (
          <ExpandMoreIcon className={styles['users-selected-matching-leads-header-chevron']} />
        ) : (
          <ExpandLessIcon className={styles['users-selected-matching-leads-header-chevron']} />
        )}
      </div>

      {isFetching && <LoadingThreeDots />}

      {!isFetching && isError && <ErrorMessage center />}

      {!isFetching &&
        finalLeads &&
        finalLeads.map((lead, index) =>
          isConsultantOpen ? (
            <ConsultantLeadsRow key={index} lead={lead} userId={user.userId} />
          ) : null
        )}
    </>
  );
};

export default React.memo(ConsultantLeads);
