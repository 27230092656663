import {
  usePaginatedTableFilters,
  useTableBooleanFilter,
  useTableFilter,
  useTableListFilter,
  useTableNumberFilter
} from '../../../shared/table/TableFilters';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setConsultantLocation, setLeadLocation } from 'redux/filterReducer';

export enum AvailabilityFilter {
  All = 'all',
  PartlyAvailable = 'partly',
  FullyAvailable = 'fully'
}

export enum ConsultantView {
  ConsultantDetails = 'consultant-details',
  MatchingLeads = 'matching-leads'
}

export const useConsultantAvailabilityFilter = () => {
  return useTableFilter<AvailabilityFilter>(undefined, 'availability', AvailabilityFilter.All);
};

export const useConsultantSearchFilter = () => {
  return useTableFilter<string>(undefined, 'search', '');
};

export const useConsultantSkillsFilter = () => {
  return useTableListFilter<string[]>(undefined, 'skills');
};

export const useConsultantView = () => {
  return useTableFilter<ConsultantView>(undefined, 'view', ConsultantView.ConsultantDetails);
};

export const useSelectedConsultantIds = () => {
  const { value, setValue } = useTableListFilter<string[]>(undefined, 'selectedConsultantIds');
  return { value: value.map((id) => Number(id)), setValue };
};

export const useSelectedConsultantId = () => {
  return useTableNumberFilter(undefined, 'selectedConsultantId');
};

export const useConsultantLocationFilter = () => {
  const dispatch = useDispatch();
  const value = useSelector((state: RootState) => state.filter.consultantLocation);

  const setValue = (value: string | null) => {
    if (value === null) {
      dispatch(setConsultantLocation(null));
      dispatch(setLeadLocation(null));
    } else {
      dispatch(setConsultantLocation(value));
      if (value === 'Småland') {
        dispatch(setLeadLocation('Växjö'));
      } else if (value === 'Karlskrona/Karlshamn') {
        dispatch(setLeadLocation('Karlskrona'));
      } else if (value === 'All') {
        dispatch(setLeadLocation(null));
      } else {
        dispatch(setLeadLocation(value));
      }
    }
  };

  return { value, setValue };
};

export const useShowSelectedConsultantsFilter = () => {
  return useTableBooleanFilter(undefined, 'showSelectedConsultants');
};

export const useConsultantsTableFilters = () => {
  return usePaginatedTableFilters('consultants');
};

export const useConsultantMatchingLeadsTableFilters = () => {
  return usePaginatedTableFilters('matchingLeads');
};
