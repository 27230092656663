import { neuronApi } from './api';

import * as TypesLeads from 'types/leads';
import * as TypesRedux from 'types/redux';

const leadApiSlice = neuronApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLeads: builder.query<
      TypesLeads.Lead[],
      { leadsAge: number; leadsLocationFilter: string | null }
    >({
      query: ({ leadsAge, leadsLocationFilter }) => ({
        url: `leads?CreatedWithinDays=${leadsAge}&locationName=${leadsLocationFilter}`,
        method: 'GET'
      }),
      providesTags: ['Leads']
    }),
    getLead: builder.query<TypesLeads.Lead, string>({
      query: (id: string) => ({
        url: `leads/${id}`,
        method: 'GET'
      }),
      providesTags: ['Leads']
    }),
    editLead: builder.mutation<void, TypesRedux.EditLeadRequest>({
      query: (body: TypesLeads.Lead) => ({
        url: 'leads/update',
        method: 'PATCH',
        body: body
      }),
      invalidatesTags: ['Leads']
    }),
    editMultipleLeads: builder.mutation<void, TypesRedux.EditLeadRequest[]>({
      query: (body: TypesLeads.Lead[]) => ({
        url: 'leads/updateMultipleLeads',
        method: 'PATCH',
        body: body
      }),
      invalidatesTags: ['Leads']
    }),
    addLead: builder.mutation<void, TypesRedux.AddLeadRequest>({
      query: (body: TypesRedux.AddLeadRequest) => ({
        url: 'leads/add',
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Leads']
    }),
    removeLead: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `leads/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Leads']
    }),
    getFindCompetenceForLeads: builder.query<TypesLeads.CompetenceForLead, string>({
      query: (leadId: string) => ({
        url: `leads/findCompetence?leadId=${leadId}`,
        method: 'GET'
      }),
      providesTags: ['Users']
    }),
    getFindCompetenceForUser: builder.query<TypesLeads.CompetenceForLead, number>({
      query: (userId: number) => ({
        url: `users/findCompetence?userId=${userId}`,
        method: 'GET'
      }),
      providesTags: ['Leads']
    }),
    getFindTopFiveCompetenceForUser: builder.query<
      TypesLeads.CompetenceForLead,
      { userId: number; leadsAge: number; locationName: string }
    >({
      query: ({ userId, leadsAge, locationName }) => ({
        url: `users/findCompetence?userId=${userId}&selectTopMatchingLeads=true&CreatedWithinDays=${leadsAge}${
          locationName && `&locationName=${locationName}`
        }`,
        method: 'GET'
      }),
      providesTags: ['Leads']
    }),
    getAllTags: builder.query<TypesLeads.Tag[], void>({
      query: () => ({
        url: 'tags/allTags',
        method: 'GET'
      })
    }),
    shareLead: builder.mutation<void, TypesRedux.ShareLeadRequest>({
      query: (body: TypesRedux.ShareLeadRequest) => ({
        url: 'leads/send',
        method: 'POST',
        body: body
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetAllLeadsQuery,
  useGetLeadQuery,
  useEditLeadMutation,
  useEditMultipleLeadsMutation,
  useAddLeadMutation,
  useRemoveLeadMutation,
  useGetFindCompetenceForLeadsQuery,
  useGetFindCompetenceForUserQuery,
  useLazyGetFindCompetenceForUserQuery,
  useGetFindTopFiveCompetenceForUserQuery,
  useGetAllTagsQuery,
  useShareLeadMutation
} = leadApiSlice;
