import { useCallback, useEffect, useRef } from 'react';
import { getRowCountForHeight } from '../functions/_functionsTable';

/**
 * Dynamically set a table's page size based on its height.
 *
 * TODO: it would be better in the future to attach the ResizeObserver to the table body instead of the table itself,
 * as we wouldn't need to pass static values for headHeight and footerHeight. But that requires forwarding refs and
 * I don't have time to work on that right now :)
 */
export function useDynamicPageSize(
  headHeight: number,
  footerHeight: number,
  rowHeight: number,
  updater: (size: number) => void
) {
  const tableRef = useRef<HTMLDivElement | null>(null);

  const updateTableSize = useCallback(() => {
    const tableHeight = tableRef.current?.clientHeight;
    if (!tableHeight) {
      return;
    }
    const rowCount = getRowCountForHeight(tableHeight - headHeight - footerHeight, rowHeight);
    updater(Math.max(1, rowCount));
  }, [footerHeight, headHeight, rowHeight, updater]);

  useEffect(() => {
    const observer = new ResizeObserver(updateTableSize);
    if (tableRef.current) {
      observer.observe(tableRef.current);
    }
    return () => observer.disconnect();
  }, [updateTableSize]);

  return { tableRef };
}
