import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from 'react-table';
import { Paper, TableContainer } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import usePluginRowSelect from './leads-table/RowSelection';
import { ActionBar } from './action-bar/ActionBar';
import LeadsTable from './leads-table/LeadsTable';
import Pagination from 'components/shared/pagination/Pagination';

import { Columns } from './leads-table/Columns';
import { RootState } from 'redux/store';
import { getTableData } from './_functionsLeads';
import * as TypesLeads from 'types/leads';

import styles from './_leads.module.css';

import {
  LEADS_TABLE_FOOTER_HEIGHT,
  LEADS_TABLE_HEAD_HEIGHT,
  LEADS_TABLE_ROW_HEIGHT
} from './leads-table/_functionsDisplayLeads';
import CopyToClipboardButton from 'components/shared/buttons/copy-to-clipboard-button/CopyToClipboardButton';
import { useLeadSearchFilter, useLeadStatusFilters } from './action-bar/LeadFilters';
import { useNeuronTable } from 'components/shared/table/useNeuronTable';
import { useDynamicPageSize } from 'components/shared/table/useDynamicPageSize';

interface Props {
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  leads: TypesLeads.Lead[];
  locations: string[];
  favorites: TypesLeads.Favorite[];
}

function LeadsContent(props: Props) {
  const { leads, locations, favorites, error, isLoading } = props;
  const { user: loggedInUser } = useSelector((state: RootState) => state.auth);

  const data = useMemo(
    () => getTableData(leads, favorites, loggedInUser?.userId),
    [leads, favorites, loggedInUser?.userId]
  );

  const { value: statusFilter } = useLeadStatusFilters();
  const { value: searchText } = useLeadSearchFilter();

  const { pagination, setFilter, setGlobalFilter, ...table } = useNeuronTable(
    {
      columns: Columns,
      data,
      initialState: {
        filters: [
          {
            id: 'status',
            value: statusFilter
          }
        ],
        globalFilter: searchText
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    usePluginRowSelect()
  );

  const { tableRef } = useDynamicPageSize(
    LEADS_TABLE_HEAD_HEIGHT,
    LEADS_TABLE_FOOTER_HEIGHT,
    LEADS_TABLE_ROW_HEIGHT,
    pagination.setPageSize
  );

  useEffect(() => {
    setFilter('status', statusFilter);
  }, [setFilter, statusFilter]);

  useEffect(() => {
    setGlobalFilter(searchText);
  }, [searchText, setGlobalFilter]);

  return (
    <>
      <ActionBar selectedLeads={table.selectedFlatRows} locations={locations} />
      <TableContainer component={Paper} className={styles['leads-table-container']} ref={tableRef}>
        <LeadsTable
          error={error}
          isLoading={isLoading}
          headerGroups={table.headerGroups}
          rows={table.page}
          getTableProps={table.getTableProps}
          getTableBodyProps={table.getTableBodyProps}
          prepareRow={table.prepareRow}
          pageSize={pagination.pageSize}
        />

        <div className={styles['leads-table-footer']}>
          <Pagination
            pageIndex={pagination.pageIndex}
            pageOptionsLength={pagination.pageOptionsLength}
            nextPage={pagination.nextPage}
            previousPage={pagination.previousPage}
            gotoPage={pagination.gotoPage}
            pageCount={pagination.pageCount}
            canNextPage={pagination.canNextPage}
            canPreviousPage={pagination.canPreviousPage}
          />
        </div>
      </TableContainer>
      <div className={styles['leads-mail-wrapper']}>
        <span className={styles['leads-mail-text']}>
          You can forward mails to:
          <CopyToClipboardButton text="stream.autoingest@softhouse.se" />
          if you want to add them to Neuron
        </span>
      </div>
    </>
  );
}

export default LeadsContent;
