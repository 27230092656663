import * as TypesLeads from 'types/leads';

export const handleFiltering = (
  shownFilter: boolean,
  selectedRowIds: number[],
  allUsers: TypesLeads.User[],
  users: TypesLeads.User[],
  selectedLocation: string | null,
  search: string,
  selectedSkills: string[]
): TypesLeads.User[] => {
  let filteredUsers: TypesLeads.User[] = users.slice(); // Clone the original array

  const filterBySearch = (user: TypesLeads.User) => {
    const searchWords = search.toLowerCase().split(' ');
    return searchWords.every(
      (word) =>
        user.firstName.toLowerCase().includes(word) || user.lastName.toLowerCase().includes(word)
    );
  };

  const filterBySelectedSkills = (user: TypesLeads.User) =>
    selectedSkills.length === 0 || // Allow all users if no skills selected
    selectedSkills.every((skill) => user.tags.some((tag) => tag.tagName === skill));

  const filterByLocation = (user: TypesLeads.User) =>
    selectedLocation === null || user.location === selectedLocation;

  filteredUsers = filteredUsers.filter((user) => {
    if (search !== '' && !filterBySearch(user)) return false;
    if (!filterBySelectedSkills(user)) return false;
    if (!filterByLocation(user)) return false;
    if (shownFilter && !selectedRowIds.includes(user.userId)) return false;
    return true;
  });

  // Sort users by sum of skill levels
  filteredUsers.sort(
    (a, b) => getSumSkillLevel(b, selectedSkills) - getSumSkillLevel(a, selectedSkills)
  );

  const selectedUsers = allUsers.filter((user) => selectedRowIds.includes(user.userId));
  selectedUsers.reverse().forEach((val) => {
    if (!filteredUsers.includes(val)) {
      filteredUsers.unshift(val);
    }
  });

  return filteredUsers;
};

/**
 * Function to get the sum of skill levels of a user
 * Users are sorted based on the sum of their skill levels in descending order.
 * Users with higher total skill levels are placed before users with lower total skill levels.
 * If multiple users have the same total skill level, their relative order from the original list is maintained.
 */
const getSumSkillLevel = (user: TypesLeads.User, selectedSkills: string[]): number => {
  return user.tags
    .filter((tag) => selectedSkills.includes(tag.tagName))
    .reduce((sum, tag) => sum + tag.level, 0);
};

export const getAllLocations = (users: TypesLeads.User[]): string[] => {
  const locationsSet = new Set(users.map((user) => user.location));
  return Array.from(locationsSet).filter((location) => location !== '' && location !== null);
};

export const getAllSkills = (users: TypesLeads.User[]): string[] => {
  const skillsSet = new Set<string>();
  users.forEach((user) => {
    user.tags.forEach((tag) => {
      skillsSet.add(tag.tagName);
    });
  });
  return Array.from(skillsSet);
};
