import { InputAdornment, TextField, Typography } from '@mui/material';
import { ChangeEvent, startTransition, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import styles from './_searchBar.module.css';

interface Props {
  defaultValue: string;
  onChange: (newValue: string) => void;
  label?: string;
}

const SearchBar = (props: Props) => {
  const { defaultValue, onChange, label = 'Search' } = props;

  const [value, setValue] = useState<string>(defaultValue);

  const handleOnChange = (value: string) => {
    setValue(value);
    startTransition(() => {
      onChange(value);
    });
  };

  return (
    <TextField
      label={<Typography sx={{ fontFamily: 'OpenSansRegular' }}>{label}</Typography>}
      value={value}
      size="small"
      className={styles['search-bar']}
      style={{ width: '100%', height: '40px', fontFamily: 'OpenSansRegular' }}
      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        handleOnChange(event.target.value)
      }
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ cursor: 'pointer', display: value ? '' : 'none' }}
            onClick={() => handleOnChange('')}>
            <ClearIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchBar;
