import { useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { HeaderGroup, useFlexLayout, usePagination, useRowSelect, useSortBy } from 'react-table';
import { Paper, TableContainer } from '@mui/material';
import sort from '../../../../assets/icons/sort.png';
import sortdesc from '../../../../assets/icons/sortdesc.png';
import sortasc from '../../../../assets/icons/sortasc.png';

import { Columns } from './Columns';
import UsersTable from './UsersTable';
import UserOverview from './UserOverview';
import { ActionBar } from '../action-bar/ActionBar';
import * as TypesLeads from 'types/leads';

import styles from './_displayUsers.module.css';
import { getAllLocations, getAllSkills, handleFiltering } from './TableFilterUtils';
import useRowSelectionConsultants from './UseRowSelectionConsultants';
import ConsultantLeadsOverview from './display-users-multiple-matching-leads/ConsultantLeadsOverview';
import {
  ConsultantView,
  useConsultantLocationFilter,
  useConsultantSearchFilter,
  useConsultantSkillsFilter,
  useConsultantView,
  useSelectedConsultantId,
  useShowSelectedConsultantsFilter
} from 'components/features/users/action-bar/ConsultantFilters';
import { useNeuronTable } from 'components/shared/table/useNeuronTable';
import { usePaginatedTableFilters } from 'components/shared/table/TableFilters';
import { useDynamicPageSize } from 'components/shared/table/useDynamicPageSize';

export const USERS_TABLE_ROW_HEIGHT = 45;
const USERS_TABLE_FOOTER_HEIGHT = 50;
const USERS_TABLE_HEAD_HEIGHT = 60;

interface Props {
  isError?: boolean;
  isLoading: boolean;
  allUsers: TypesLeads.User[];
  users: TypesLeads.User[];
  matchingLoaded: boolean;
  hiddenColumns: string[];
}

function UsersContent(props: Props) {
  const { allUsers, users, isError, isLoading, matchingLoaded, hiddenColumns } = props;
  const { value: showSelectedUsersFilter } = useShowSelectedConsultantsFilter();
  const { value: locationFilter } = useConsultantLocationFilter();
  const { value: searchFilter } = useConsultantSearchFilter();
  const { value: skillsFilter } = useConsultantSkillsFilter();
  const { selectedRowIds: selectedConsultantIds } = usePaginatedTableFilters('consultants');
  const { value: view, setValue: setView } = useConsultantView();
  const locationObject = useLocation();
  const [searchParams] = useSearchParams();
  const isForLead = searchParams.has('forLead');
  const columns = useMemo(() => Columns(isForLead), [isForLead]);
  const allLocations = useMemo(() => getAllLocations(users), [users]);
  const allSkills = useMemo(() => getAllSkills(users), [users]);

  const [activeCheckedUserIndex, setActiveCheckedUserIndex] = useState(-1);

  const data = useMemo(
    () =>
      handleFiltering(
        showSelectedUsersFilter,
        selectedConsultantIds.map((id) => Number(id)),
        allUsers,
        users,
        locationFilter,
        searchFilter,
        skillsFilter
      ),
    [
      allUsers,
      locationFilter,
      searchFilter,
      selectedConsultantIds,
      showSelectedUsersFilter,
      skillsFilter,
      users
    ]
  );

  const table = useNeuronTable(
    {
      tableId: 'consultants',
      columns,
      data,
      hiddenColumns,
      getRowId: (row) => String(row.userId)
    },
    useFlexLayout,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowSelectionConsultants()
  );

  const {
    pagination: { pageSize, setPageSize, gotoPage }
  } = table;

  const { value: selectedConsultantId, setValue: setSelectedConsultantId } =
    useSelectedConsultantId();

  const getDefaultSelectedUser = () => {
    const locationUserId = locationObject.state?.userId;
    if (locationUserId) {
      return users.find((item) => item.userId === locationUserId);
    }
    return users.length > 0 ? users[0] : undefined;
  };

  // TODO: remove cast when child components accept undefined (we fooled TS previously as well)
  const selectedUser =
    users.find((item) => item.userId === selectedConsultantId) ||
    (getDefaultSelectedUser() as TypesLeads.User);

  const onActiveCheckedUserIndexChange = (index: number) => {
    const activeCheckedUser =
      table.selectedFlatRows.length > 0 ? table.selectedFlatRows[index]?.original : undefined;
    if (activeCheckedUser) {
      setSelectedConsultantId(activeCheckedUser.userId);
      const selectedUserIndex = data.findIndex((item) => item.userId === activeCheckedUser.userId);
      const userPageIndex = Math.floor(selectedUserIndex / pageSize);
      gotoPage(userPageIndex);
    }
    setActiveCheckedUserIndex(index);
  };

  const { tableRef } = useDynamicPageSize(
    USERS_TABLE_HEAD_HEIGHT,
    USERS_TABLE_FOOTER_HEIGHT,
    USERS_TABLE_ROW_HEIGHT,
    setPageSize
  );

  const showSortingArrow = (column: HeaderGroup<TypesLeads.User>) => {
    if (column.isSorted) {
      if (column.isSortedDesc) return sortdesc;
      return sortasc;
    }
    return sort;
  };

  return (
    <div className={styles['users-page-container']}>
      <ActionBar
        allLocations={allLocations}
        allSkills={allSkills}
        isLeadsView={view == ConsultantView.MatchingLeads}
        setIsLeadsView={(isLeadsView: boolean) =>
          isLeadsView
            ? setView(ConsultantView.MatchingLeads)
            : setView(ConsultantView.ConsultantDetails)
        }
        isForLead={isForLead}
        showApplyHereLink={false}
      />
      <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
        <div className={styles['users-table-wrapper']}>
          <div style={{ position: 'sticky', top: '0.5rem' }}>
            <TableContainer
              component={Paper}
              className={styles['users-table-container']}
              ref={tableRef}>
              <div className={styles['users-table-head']}>
                {table.headerGroups.map(
                  (headerGroup: HeaderGroup<TypesLeads.User>, index: number) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      key={index}
                      className={styles['users-table-head-row']}>
                      {headerGroup.headers.map((column: HeaderGroup<TypesLeads.User>) => {
                        if (column.id === 'selection')
                          return (
                            <div key={column.id} style={{ width: '40px', paddingLeft: '9px' }}>
                              {column.render('Header')}
                            </div>
                          );
                        return (
                          <div
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            key={column.id}
                            className={
                              column.Header === 'Name'
                                ? styles['users-table-head-cell-name']
                                : column.Header === 'Assigned'
                                ? styles['users-table-head-cell-assigned']
                                : styles['users-table-head-cell']
                            }>
                            {column.render('Header')}
                            {column.canSort && (
                              <img
                                src={showSortingArrow(column)}
                                className={styles['users-table-head-cell-sorting-arrow']}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )
                )}
              </div>
              <UsersTable
                isError={isError}
                isLoading={isLoading}
                rows={table.page}
                totalUsers={data.length}
                getTableProps={table.getTableProps}
                getTableBodyProps={table.getTableBodyProps}
                prepareRow={table.prepareRow}
                isFilter={Boolean(searchFilter) && searchFilter.length > 0}
                paginationData={table.pagination}
                pageSize={table.pagination.pageSize}
                setSelectedUser={(user) => setSelectedConsultantId(user.userId)}
                selectedUser={selectedUser}
                setActiveCheckedUserIndex={onActiveCheckedUserIndexChange}
              />
            </TableContainer>
          </div>
        </div>
        {view == ConsultantView.MatchingLeads ? (
          <ConsultantLeadsOverview users={table.selectedFlatRows} singleUser={selectedUser} />
        ) : (
          <UserOverview
            user={selectedUser}
            isLeadsView={false}
            isLoading={isLoading}
            matchingLoaded={matchingLoaded}
            isForLead={isForLead}
            hasReturned={locationObject.state?.hasReturned}
            myLeads={locationObject.state?.myLeads}
            userId={locationObject.state?.userId}
            selectedFlatRows={table.selectedFlatRows}
            activeCheckedUserIndex={activeCheckedUserIndex}
            setActiveCheckedUserIndex={onActiveCheckedUserIndexChange}
          />
        )}
      </div>
    </div>
  );
}

export default UsersContent;
