import PageLayout from 'components/layouts/PageLayout';
import LeadsContent from 'components/features/leads/LeadsContent';

import { useGetAllLeadsQuery } from 'redux/leadApi';
import { useGetFavoritesQuery } from 'redux/favoriteApi';
import { useGetAllUsersQuery } from 'redux/userApi';
import { getAllLocations } from 'components/features/users/display-users/TableFilterUtils';
import {
  useLeadAgeFilter,
  useLeadLocationFilter
} from 'components/features/leads/action-bar/LeadFilters';
import { useSyncGlobalFilters } from 'components/features/leads/action-bar/GlobalFilters';

const Leads = () => {
  useSyncGlobalFilters();
  const { value: leadsAge } = useLeadAgeFilter();
  const { value: leadLocation } = useLeadLocationFilter();
  const {
    data: leads,
    error,
    isFetching
  } = useGetAllLeadsQuery(
    { leadsAge, leadsLocationFilter: leadLocation },
    { refetchOnMountOrArgChange: true }
  );
  const { data: favorites } = useGetFavoritesQuery();
  const { data: usersOriginal = [] } = useGetAllUsersQuery();

  const locations = getAllLocations(usersOriginal);

  return (
    <PageLayout>
      <LeadsContent
        leads={leads ?? []}
        locations={locations}
        favorites={favorites ?? []}
        error={error}
        isLoading={isFetching}
      />
    </PageLayout>
  );
};

export default Leads;
