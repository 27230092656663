import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useLeadAgeFilter, useLeadLocationFilter } from './LeadFilters';
import { useConsultantLocationFilter } from 'components/features/users/action-bar/ConsultantFilters';

export function useSyncGlobalFilters() {
  const [, setSearchParams] = useSearchParams();
  const { value: leadAge } = useLeadAgeFilter();
  const { value: leadLocation } = useLeadLocationFilter();
  const { value: consultantLocation } = useConsultantLocationFilter();

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (leadAge === null) {
          prev.delete('age');
        } else {
          prev.set('age', leadAge.toString());
        }
        if (leadLocation === null) {
          prev.delete('leadLocation');
        } else {
          prev.set('leadLocation', leadLocation);
        }
        if (consultantLocation === null) {
          prev.delete('consultantLocation');
        } else {
          prev.set('consultantLocation', consultantLocation);
        }
        return prev;
      },
      { replace: true }
    );
  }, [consultantLocation, leadAge, leadLocation, setSearchParams]);
}
