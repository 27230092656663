import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Statistics from './pages/Statistics';
import Leads from './pages/Leads';
import Lead from './pages/Lead';
import Users from 'pages/Users';
import Login from './pages/Login';

import MainOutlet from './components/layouts/MainOutlet';
import ProtectedRoute from './components/shared/ProtectedRoute';
import About from 'pages/About';
import PhoneError from 'pages/PhoneError';

function App() {
  const userAgent = navigator.userAgent.toLowerCase();
  if (
    userAgent.search('iphone') > -1 ||
    userAgent.search('ipad') > -1 ||
    userAgent.search('ipod') > -1 ||
    userAgent.search('android') > -1
  ) {
    return (
      <Router>
        <Routes>
          <Route element={<PhoneError />} path="/" />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<MainOutlet />}>
              <Route index element={<Leads />} />
              <Route path="/statistics" index element={<Statistics />} />
              <Route path="/leads">
                <Route path=":leadId" element={<Lead />} />
              </Route>
              <Route path="/consultants" element={<Users />} />
              <Route>
                <Route path="/about" element={<About />} />
              </Route>
            </Route>
          </Route>
          <Route element={<Login redirectPath={location.pathname} />} path="/login" />
        </Routes>
      </Router>
    );
  }
}

export default App;
