import { FormControl, Select, MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import React from 'react';

type Props = {
  value: number | null;
  onChange: (value: number) => void;
};

const LeadAgeFilter = ({ value, onChange }: Props) => {
  const handleOnChange = (element: SelectChangeEvent) => {
    onChange(parseInt(element.target.value));
  };

  return (
    <FormControl style={{ width: '30%' }}>
      <InputLabel id="leadAge">Lead Age</InputLabel>
      <Select
        labelId="leadAge"
        label="Lead Age"
        sx={{ backgroundColor: 'white', height: '40px', fontFamily: 'OpenSansRegular' }}
        value={value?.toString() ?? '0'}
        onChange={handleOnChange}>
        <MenuItem value={1}>1 day</MenuItem>
        <MenuItem value={7}>7 days</MenuItem>
        <MenuItem value={30}>30 days</MenuItem>
        <MenuItem value={0}>All</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LeadAgeFilter;
