import React, { useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material';

import Pagination from 'components/shared/pagination/Pagination';

import * as TypesLeads from 'types/leads';

import styles from './_singleUserLeads.module.css';
import { useNavigate } from 'react-router-dom';
import { useGetAllLeadsQuery, useGetFindCompetenceForUserQuery } from 'redux/leadApi';
import {
  filterLeads,
  getLeadsWithCompetenceForUser,
  getProgressValue,
  sortLeadsByMatch
} from 'components/features/leads-for-user/_functionsDisplayLeads';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
import { getCellTooltipText } from 'components/features/leads/leads-table/_functionsDisplayLeads';
import { usePagination, HeaderGroup, Row, Cell, CellProps, Column } from 'react-table';
import ConsultantSkillProgress from '../display-users-multiple-matching-leads/ConsultantSkillProgress';
import { LeadDueDate } from 'components/features/leads/leads-table/LeadDueDate';
import LeadStatusList from 'components/features/leads/leads-table/LeadStatusList';
import {
  useLeadAgeFilter,
  useLeadLocationFilter
} from 'components/features/leads/action-bar/LeadFilters';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNeuronTable } from 'components/shared/table/useNeuronTable';
import { useDynamicPageSize } from 'components/shared/table/useDynamicPageSize';
import RowFindCompetenceBtn from 'components/features/leads/leads-table/RowFindCompetenceBtn';

const USERS_TABLE_ROW_HEIGHT = 45;
const USERS_TABLE_FOOTER_HEIGHT = 50;
const USERS_TABLE_HEAD_HEIGHT = 116;

const Columns: Column<TypesLeads.Lead>[] = [
  {
    accessor: 'companyName',
    Header: 'Consultant Broker'
  },
  {
    accessor: 'role',
    Header: 'Role'
  },
  {
    accessor: (info) => info.competenceStatus?.matchWeights?.tag,
    Header: 'Skills',
    Cell(info: CellProps<TypesLeads.Lead>) {
      return (
        <ConsultantSkillProgress
          value={getProgressValue(info?.row?.original?.competenceStatus?.matchWeights?.tag)}
        />
      );
    }
  },
  {
    accessor: (info) => info.competenceStatus?.matchWeights?.availability,
    Header: 'Availability',
    Cell(info: CellProps<TypesLeads.Lead>) {
      return (
        <ConsultantSkillProgress
          value={getProgressValue(
            info?.row?.original?.competenceStatus?.matchWeights?.availability
          )}
        />
      );
    }
  },
  {
    accessor: 'location',
    Header: 'Location'
  },
  {
    accessor: 'lastApplicationDate',
    Header: 'Due date',
    Cell(info: CellProps<TypesLeads.Lead>) {
      return <LeadDueDate dueDate={info.row.original.lastApplicationDate} />;
    }
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (lead: TypesLeads.Lead) => <LeadStatusList lead={lead} />
  },
  {
    Header: ' ',
    id: 'findCompetenceIcon',
    accessor: (lead: TypesLeads.Lead) => <RowFindCompetenceBtn lead={lead} />
  }
];
interface Props {
  user: TypesLeads.User;
}

function UserLeads(props: Props) {
  const { user } = props;
  const navigate = useNavigate();
  const { value: leadsAge } = useLeadAgeFilter();
  const { value: leadLocation } = useLeadLocationFilter();
  const { data: leadsOriginal = [] } = useGetAllLeadsQuery({
    leadsAge,
    leadsLocationFilter: leadLocation
  });
  const { data: userWithCompetenceForLeads, isFetching } = useGetFindCompetenceForUserQuery(
    user?.userId ?? skipToken
  );
  const leadsFiltered = useMemo(() => filterLeads(leadsOriginal), [leadsOriginal]);
  const leadsMerged: TypesLeads.Lead[] = useMemo(
    () => getLeadsWithCompetenceForUser(leadsFiltered, userWithCompetenceForLeads?.matches),
    [leadsFiltered, userWithCompetenceForLeads?.matches]
  );
  const data = useMemo(() => sortLeadsByMatch(leadsMerged), [leadsMerged]);

  const table = useNeuronTable(
    {
      tableId: 'consultantLeads',
      columns: Columns,
      data
    },
    usePagination
  );

  const {
    pagination: { setPageSize }
  } = table;

  const { tableRef } = useDynamicPageSize(
    USERS_TABLE_HEAD_HEIGHT,
    USERS_TABLE_FOOTER_HEIGHT,
    USERS_TABLE_ROW_HEIGHT,
    setPageSize
  );

  const handleOnClick = (row) => {
    navigate(`/leads/${row.original.leadId}`, {
      state: {
        returnLocation: 'singleConsultant',
        firstName: user?.firstName,
        userId: user?.userId
      }
    });
  };

  return (
    <>
      {!isFetching && (
        <TableContainer
          component={Paper}
          className={styles['user-matching-leads-table-container']}
          ref={tableRef}>
          <Table {...table.getTableProps()} className={styles['user-matching-leads-table']}>
            <TableHead className={styles['user-matching-leads-table-head']}>
              {table.headerGroups.map(
                (headerGroup: HeaderGroup<TypesLeads.Lead>, index: number) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column: HeaderGroup<TypesLeads.Lead>) => (
                      <TableCell
                        {...column.getHeaderProps()}
                        key={column.id}
                        className={styles['user-matching-table-head-cell']}>
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              )}
            </TableHead>
            <TableBody
              {...table.getTableBodyProps()}
              className={styles['user-matching-table-body']}>
              {table.page.map((row: Row<TypesLeads.Lead>, index) => {
                table.prepareRow(row);
                row.getRowProps();
                return (
                  <TableRow key={index} className={styles['user-matching-leads-table-row']}>
                    {row.cells.map((cell: Cell<TypesLeads.Lead>) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          key={cell.column.id}
                          className={styles['user-matching-leads-table-row-cell']}
                          title={getCellTooltipText(cell)}
                          onClick={() => handleOnClick(row)}>
                          <Box className={styles['user-matching-leads-table-row-cell-content']}>
                            {cell.render('Cell')}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Table>
            <TableFooter className={styles['user-matching-leads-table-footer']}>
              <TableRow>
                <TableCell className={styles['user-matching-leads-table-pagination']}>
                  <Pagination
                    pageIndex={table.pagination.pageIndex}
                    pageOptionsLength={table.pagination.pageOptionsLength}
                    nextPage={table.pagination.nextPage}
                    previousPage={table.pagination.previousPage}
                    gotoPage={table.pagination.gotoPage}
                    pageCount={table.pagination.pageCount}
                    canNextPage={table.pagination.canNextPage}
                    canPreviousPage={table.pagination.canPreviousPage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
      {isFetching && <LoadingThreeDots />}
    </>
  );
}

export default React.memo(UserLeads);
