import { useState } from 'react';
import { Row } from 'react-table';
import moment from 'moment';
import {
  Button,
  ClickAwayListener,
  Fade,
  MenuItem,
  Paper,
  Popper,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { useEditMultipleLeadsMutation } from 'redux/leadApi';

import SearchBar from 'components/shared/search-bar/SearchBar';
import AddLead from './AddLead';
import CSVExporter from './CSVExporter';

import * as TypesLeads from 'types/leads';

import styles from './_actionBar.module.css';
import LeadAgeFilter from 'components/features/users/action-bar/LeadAgeFilter';
import {
  LeadResult,
  useLeadAgeFilter,
  useLeadLocationFilter,
  useLeadSearchFilter,
  useLeadStatusFilters
} from './LeadFilters';

interface Props {
  selectedLeads: Row<TypesLeads.Lead>[];
  locations: string[];
}

export const ActionBar = (props: Props) => {
  const { selectedLeads } = props;

  const [editLeadsAnchorElement, setEditLeadsAnchorElement] = useState<HTMLButtonElement | null>(
    null
  );
  const [filterAnchorElement, setFilterAnchorElement] = useState<HTMLButtonElement | null>(null);

  const openEditLeads = Boolean(editLeadsAnchorElement);
  const openFilter = Boolean(filterAnchorElement);
  const [editMultipleLeads] = useEditMultipleLeadsMutation();

  const {
    value: statusFilter,
    count: statusFilterCount,
    setRelevant,
    setAnswered,
    setAssigned,
    setResult,
    setFavorite
  } = useLeadStatusFilters();

  const { value: leadAge, setValue: setLeadAge } = useLeadAgeFilter();
  const { value: leadLocation, setValue: setLeadLocation } = useLeadLocationFilter();

  const handleOnRelevantStatusChange = (_, value: any) => {
    setRelevant(value == null ? '' : value);
  };

  const handleOnAnsweredStatusChange = (_, value: any) => {
    setAnswered(value);
  };

  const handleOnResultStatusChange = (_, value: any) => {
    setResult(value);
  };

  const handleOnFavoriteChange = (_, value: any) => {
    setFavorite(value);
  };

  const handleOnAssignedChange = (_, value: any) => {
    setAssigned(value);
  };

  const handleClickEditLeads = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEditLeadsAnchorElement(event.currentTarget);
  };

  const handleCloseEditLeads = () => {
    setEditLeadsAnchorElement(null);
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorElement(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterAnchorElement(null);
  };

  const handleEditLead = (action: string) => {
    const mutatedLeads = selectedLeads.map((val) => {
      return {
        leadId: val.original.leadId,
        irrelevant: {
          date: moment().format('YYYY-MM-DD HH:mm'),
          value: action == 'irrelevant' ? true : false
        }
      };
    });
    editMultipleLeads(mutatedLeads).unwrap();
    handleCloseEditLeads();
  };

  return (
    <div className={styles['action-bar']}>
      <div className={styles['action-bar-triggers']}>
        <div className={styles['action-bar-triggers-group']} style={{ maxWidth: '500px' }}>
          <AddLead />
          <Button
            onClick={handleClickEditLeads}
            disabled={selectedLeads.length <= 0}
            sx={{ marginLeft: '0.3rem' }}>
            Edit leads
          </Button>
        </div>

        <div className={styles['action-bar-triggers-group']} style={{ maxWidth: '800px' }}>
          <Button
            size="small"
            className={styles['action-bar-triggers-group-filter-button']}
            onClick={handleClickFilter}>
            <FilterAltRoundedIcon />
            <span>Filters</span>
            {statusFilterCount > 0 && (
              <span className={styles['action-bar-triggers-group-filter-button-chip']}>
                {statusFilterCount}
              </span>
            )}
          </Button>
          <LeadLocationFilter value={leadLocation} onChange={setLeadLocation} />
          <LeadAgeFilter value={leadAge} onChange={setLeadAge} />
          <LeadSearchBar />
        </div>
      </div>

      <Popper
        open={openEditLeads}
        anchorEl={editLeadsAnchorElement}
        placement="bottom-start"
        transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseEditLeads}>
                <div className={styles['action-bar-edit-leads-popper']}>
                  <Button
                    onClick={() => handleEditLead('relevant')}
                    className={styles['action-bar-edit-leads-popper-button']}>
                    Relevant
                  </Button>
                  <Button
                    onClick={() => handleEditLead('irrelevant')}
                    className={styles['action-bar-edit-leads-popper-button']}>
                    Irrelevant
                  </Button>
                  <CSVExporter selectedLeads={selectedLeads} />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>

      <Popper open={openFilter} anchorEl={filterAnchorElement} placement="bottom-end" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseFilter}>
                <div className={styles['action-bar-toggle-groups']}>
                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={statusFilter.answered}
                    sx={{ marginBottom: '0.7rem' }}
                    onChange={handleOnAnsweredStatusChange}>
                    <ToggleButton value={true}>Answered</ToggleButton>
                    <ToggleButton value={false}>Unanswered</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={statusFilter.result}
                    sx={{ marginBottom: '0.7rem' }}
                    onChange={handleOnResultStatusChange}>
                    <ToggleButton value={LeadResult.Won}>Won</ToggleButton>
                    <ToggleButton value={LeadResult.Lost}>Lost</ToggleButton>
                    <ToggleButton value={LeadResult.None}>No Result</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={statusFilter.relevant}
                    sx={{ marginBottom: '0.7rem' }}
                    onChange={handleOnRelevantStatusChange}>
                    <ToggleButton value={true}>Relevant</ToggleButton>
                    <ToggleButton value={false}>Irrelevant</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={statusFilter.favorite}
                    sx={{ marginBottom: '0.7rem' }}
                    onChange={handleOnFavoriteChange}>
                    <ToggleButton value={true}>Favorite</ToggleButton>
                    <ToggleButton value={false}>Not favorite</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup
                    exclusive={true}
                    color="primary"
                    size="small"
                    value={statusFilter.assigned}
                    onChange={handleOnAssignedChange}>
                    <ToggleButton value={true}>Assigned To</ToggleButton>
                    <ToggleButton value={false}>Not Assigned</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

const LeadSearchBar = () => {
  const { value, setValue } = useLeadSearchFilter();
  return (
    <div className={styles['action-bar-search']}>
      <SearchBar defaultValue={value} onChange={setValue} label="Search leads" />
    </div>
  );
};

interface LeadLocationFilterProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const LeadLocationFilter = ({ value, onChange }: LeadLocationFilterProps) => {
  const handleOnChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value == 'All' ? null : event.target.value);
  };

  return (
    <Select
      className={styles['action-bar-date-range-select']}
      style={{ width: '250px' }}
      value={value || 'All'}
      onChange={handleOnChange}
      displayEmpty>
      <MenuItem value={'All'}>All locations</MenuItem>
      <MenuItem value={'Malmö'}>Malmö</MenuItem>
      <MenuItem value={'Karlskrona'}>Karlskrona</MenuItem>
      <MenuItem value={'Växjö'}>Växjö</MenuItem>
      <MenuItem value={'Karlshamn'}>Karlshamn</MenuItem>
      <MenuItem value={'Jönköping'}>Jönköping</MenuItem>
      <MenuItem value={'Stockholm'}>Stockholm</MenuItem>
      <MenuItem value={'Luleå'}>Luleå</MenuItem>
      <MenuItem value={'Uppsala'}>Uppsala</MenuItem>
      <MenuItem value={'Kalmar'}>Kalmar</MenuItem>
      <MenuItem value={'Sarajevo'}>Sarajevo</MenuItem>
      <MenuItem value={'Remote'}>Remote (Beta)</MenuItem>
    </Select>
  );
};
