import { SelectChangeEvent, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import React from 'react';

type Props = {
  allLocations: string[];
  value: string | null;
  onChange: (value: string | null) => void;
};

export const LocationFilter = (props: Props) => {
  const { value, onChange } = props;

  const handleChange = (event: SelectChangeEvent) => {
    const location = event.target.value == 'All' ? null : event.target.value;
    onChange(location);
  };

  return (
    <Box sx={{ minWidth: 180, width: '30%' }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Location</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label="Location"
          sx={{ backgroundColor: 'white', height: '40px', fontFamily: 'OpenSansRegular' }}
          value={value || 'All'}
          onChange={handleChange}>
          <MenuItem value={'All'}>All locations</MenuItem>
          <MenuItem value={'Malmö'}>Malmö</MenuItem>
          <MenuItem value={'Karlskrona'}>Karlskrona</MenuItem>
          <MenuItem value={'Småland'}>Småland</MenuItem>
          <MenuItem value={'Karlshamn'}>Karlshamn</MenuItem>
          <MenuItem value={'Stockholm'}>Stockholm</MenuItem>
          <MenuItem value={'Luleå'}>Luleå</MenuItem>
          <MenuItem value={'Uppsala'}>Uppsala</MenuItem>
          <MenuItem value={'Sarajevo'}>Sarajevo</MenuItem>
          <MenuItem value={'Karlskrona/Karlshamn'}>Karlskrona/Karlshamn</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
