import { createSlice } from '@reduxjs/toolkit';

export interface FilterState {
  leadAge: number;
  leadLocation: string | null;
  consultantLocation: string | null;
}

const searchParams = new URLSearchParams(window.location.search);

const initialState = {
  leadAge: searchParams.get('age') || 7,
  leadLocation: searchParams.get('leadLocation'),
  consultantLocation: searchParams.get('consultantLocation')
} as FilterState;

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setLeadAge: (state, action) => {
      state.leadAge = action.payload;
    },
    setLeadLocation: (state, action) => {
      state.leadLocation = action.payload;
    },
    setConsultantLocation: (state, action) => {
      state.consultantLocation = action.payload;
    }
  }
});

export const { setLeadAge, setLeadLocation, setConsultantLocation } = filterSlice.actions;
export default filterSlice.reducer;
