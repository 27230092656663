import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { neuronApi } from './api';
import authReducer from './authSlice';

import { authenticatedCheckMiddleware, onResponseMiddleware } from './middlewares';
import filterReducer from './filterReducer';

export const store = configureStore({
  reducer: {
    [neuronApi.reducerPath]: neuronApi.reducer,
    filter: filterReducer,
    auth: authReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      neuronApi.middleware,
      onResponseMiddleware,
      authenticatedCheckMiddleware
    ])
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
