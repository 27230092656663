import { SelectChangeEvent, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import React from 'react';
import { AvailabilityFilter as AvailabilityFilterValue } from 'components/features/users/action-bar/ConsultantFilters';

type Props = {
  value: AvailabilityFilterValue;
  onChange: (value: AvailabilityFilterValue) => void;
};

const AvailabilityFilter = ({ value, onChange }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as AvailabilityFilterValue);
  };

  return (
    <Box sx={{ minWidth: 180, width: '30%' }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Availability</InputLabel>
        <Select
          sx={{ backgroundColor: 'white', height: '40px', fontFamily: 'OpenSansRegular' }}
          value={value}
          onChange={handleChange}
          labelId="demo-simple-select-label"
          label="Availability">
          <MenuItem value={AvailabilityFilterValue.All}>All</MenuItem>
          <MenuItem value={AvailabilityFilterValue.PartlyAvailable}>Partly Available</MenuItem>
          <MenuItem value={AvailabilityFilterValue.FullyAvailable}>Fully Available</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default AvailabilityFilter;
