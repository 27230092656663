import { SelectChangeEvent, Box, FormControl, Select, MenuItem } from '@mui/material';
import React from 'react';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const ShownFilter = (props: Props) => {
  const { value, onChange } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value === 'true');
  };

  return (
    <Box sx={{ minWidth: 170 }}>
      <FormControl fullWidth>
        <Select
          sx={{ backgroundColor: 'white', height: '40px', fontFamily: 'OpenSansRegular' }}
          value={value.toString()}
          onChange={handleChange}>
          <MenuItem value={'false'}>Show All</MenuItem>
          <MenuItem value={'true'}>Show Selected</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
