import { store } from 'redux/store';
import { PropsWithChildren } from 'react';
import { ActionType, Cell, HeaderProps, Row, TableState } from 'react-table';

import * as TypesLeads from 'types/leads';
import { LeadResult, useLeadStatusFilters } from '../action-bar/LeadFilters';

export const LEADS_TABLE_ROW_HEIGHT = 45;
export const LEADS_TABLE_HEAD_HEIGHT = 100;
export const LEADS_TABLE_FOOTER_HEIGHT = 50;

function getFilteredRowsForResult(result: LeadResult, rows: Row<TypesLeads.Lead>[]) {
  let rawResult: boolean | null = null;
  switch (result) {
    case LeadResult.Won:
      rawResult = true;
      break;
    case LeadResult.Lost:
      rawResult = false;
      break;
    case LeadResult.None:
      rawResult = null;
      break;
  }

  return rows.filter(
    (row: Row<TypesLeads.Lead>) => (row.original as TypesLeads.Lead).result?.value === rawResult
  );
}

function getFilteredRowsForAnswered(answered: boolean, rows: Row<TypesLeads.Lead>[]) {
  return rows.filter(
    (row: Row<TypesLeads.Lead>) => (row.original as TypesLeads.Lead).answered?.value === answered
  );
}

function getFilteredRowsForRelevant(relevant: boolean, rows: Row<TypesLeads.Lead>[]) {
  return rows.filter(
    (row: Row<TypesLeads.Lead>) => (row.original as TypesLeads.Lead).irrelevant?.value !== relevant
  );
}

function getFilteredRowsForFavorite(favorite: boolean, rows: Row<TypesLeads.Lead>[]) {
  return rows.filter(
    (row: Row<TypesLeads.Lead>) => (row.original as TypesLeads.Lead)?.isFavorite === favorite
  );
}

function getFilteredRowsForAssignedToMe(assigned: boolean, rows: Row<TypesLeads.Lead>[]) {
  const { user: loggedInUser } = store.getState().auth;
  if (assigned) {
    return rows.filter(
      (row: Row<TypesLeads.Lead>) =>
        (row.original as TypesLeads.Lead)?.assignedSalespersons?.includes(loggedInUser?.userId) ===
        assigned
    );
  }
  return rows;
}

export function getStatusFilters(
  rows: Row<TypesLeads.Lead>[],
  id: string[],
  filters: ReturnType<typeof useLeadStatusFilters>['value']
) {
  if (filters.answered != null) {
    rows = getFilteredRowsForAnswered(filters.answered, rows);
  }

  if (filters.relevant !== null) {
    rows = getFilteredRowsForRelevant(filters.relevant, rows);
  }

  if (filters.result !== null) {
    rows = getFilteredRowsForResult(filters.result, rows);
  }

  if (filters.favorite !== null) {
    rows = getFilteredRowsForFavorite(filters.favorite, rows);
  }

  if (filters.assigned !== null) {
    rows = getFilteredRowsForAssignedToMe(filters.assigned, rows);
  }

  return rows;
}

interface GetConditionalSelectHeaderCheckboxProps {
  headerProps: PropsWithChildren<HeaderProps<TypesLeads.Lead>>;
  checkIfRowIsSelectable: (row: Row<TypesLeads.Lead>) => boolean;
  shouldSelectPage?: boolean;
}

export const getConditionalSelectHeaderCheckboxProps = ({
  headerProps,
  checkIfRowIsSelectable,
  shouldSelectPage = true
}: GetConditionalSelectHeaderCheckboxProps) => {
  const checkIfAllSelectableRowsSelected = (rows: Row<TypesLeads.Lead>[]) =>
    rows.filter(checkIfRowIsSelectable).every((row: Row<TypesLeads.Lead>) => row.isSelected);

  const isSelectPage =
    shouldSelectPage &&
    headerProps.page?.filter(checkIfRowIsSelectable).some((row) => !row.isSelected);

  const checkboxProps = isSelectPage
    ? headerProps.getToggleAllPageRowsSelectedProps()
    : headerProps.getToggleAllRowsSelectedProps();

  const disabled = headerProps.rows.filter(checkIfRowIsSelectable).length === 0;
  const checked = !disabled && checkIfAllSelectableRowsSelected(headerProps.rows);
  const indeterminate =
    !checked && headerProps.rows.some((row: Row<TypesLeads.Lead>) => row.isSelected);

  const onChange = () => {
    if (!isSelectPage && checkIfAllSelectableRowsSelected(headerProps.rows)) {
      headerProps.rows.forEach((row: Row<TypesLeads.Lead>) => {
        headerProps.toggleRowSelected(row.id, false);
      });
    } else {
      const rows = isSelectPage ? headerProps.page : headerProps.rows;
      rows.forEach((row: Row<TypesLeads.Lead>) => {
        const checked = checkIfRowIsSelectable(row);
        headerProps.toggleRowSelected(row.id, checked);
      });
    }
  };

  return {
    ...checkboxProps,
    checked,
    indeterminate,
    disabled,
    onChange
  };
};

export const getCellTooltipText = (cell: Cell<TypesLeads.Lead>): string => {
  const leadData: TypesLeads.Lead = cell.row.original;
  if (cell.column.id === 'status') {
    const statuses: string[] = [];
    leadData.answered.value === true ? statuses.push('answered') : statuses.push('unanswered');
    leadData.result.value === true
      ? statuses.push('won')
      : leadData.result.value === false
      ? statuses.push('lost')
      : statuses.push('no result');
    return statuses.join(', ');
  }

  return cell.value?.toString();
};

export const getLeadsTablePageSize = (windowHeight: number): number => {
  const tableHeight = windowHeight - 150;
  const LEADS_TABLE_BODY_HEIGHT = tableHeight - LEADS_TABLE_HEAD_HEIGHT - LEADS_TABLE_FOOTER_HEIGHT;
  return Math.max(1, Math.floor(LEADS_TABLE_BODY_HEIGHT / LEADS_TABLE_ROW_HEIGHT));
};

export const leadsTableReducer = (
  newState: TableState<TypesLeads.Lead>,
  action: ActionType,
  previousState: TableState<TypesLeads.Lead>
): TableState<TypesLeads.Lead> => {
  if (action.type === 'gotoPage') {
    return newState;
  }
  return { ...newState, pageIndex: previousState.pageIndex };
};
